<template>
  <div class="text-dark">
    <ModalHeader
      :left-text="'Kembali'"
      :middle-text="'Detail Transaksi'"
      :left-function="prevPage"
    />
    <div class="mt-5 pt-5">
      <BillingVirtualAccountBCA
        :detail-subscription="detailSubscription"
        :get-detail="getDetail"
      />
      <BillingVirtualAccountBRI
        :detail-subscription="detailSubscription"
        :get-detail="getDetail"
      />
      <BillingVirtualAccountBNI
        :detail-subscription="detailSubscription"
        :get-detail="getDetail"
      />
      <BillingVirtualAccountBJB
        :detail-subscription="detailSubscription"
        :get-detail="getDetail"
      />
      <BillingVirtualAccountPERMATA
        :detail-subscription="detailSubscription"
        :get-detail="getDetail"
      />
      <BillingVirtualAccountMandiri
        :detail-subscription="detailSubscription"
        :get-detail="getDetail"
      />
      <BillingVirtualAccountCIMB
        :detail-subscription="detailSubscription"
        :get-detail="getDetail"
      />
      <BillingVirtualAccountBSI
        :detail-subscription="detailSubscription"
        :get-detail="getDetail"
      />
      <BillingQR
        :detail-subscription="detailSubscription"
        :get-detail="getDetail"
      />
    </div>
  </div>
</template>

<script>
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BCard,
  BButton,
  BFormCheckbox,
  BFormRadio,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";

import ModalHeader from "@/components/ModalHeader.vue";
import BillingVirtualAccountBCA from "@/components/Billing/BillingVirtualAccountBCA.vue";
import BillingVirtualAccountBRI from "@/components/Billing/BillingVirtualAccountBRI.vue";
import BillingVirtualAccountBNI from "@/components/Billing/BillingVirtualAccountBNI.vue";
import BillingVirtualAccountBJB from "@/components/Billing/BillingVirtualBJB.vue";
import BillingVirtualAccountPERMATA from "@/components/Billing/BillingVirtualAccountPERMATA.vue";
import BillingVirtualAccountMandiri from "@/components/Billing/BillingVirtualAccountMandiri.vue";
import BillingVirtualAccountBSI from "@/components/Billing/BillingVirtualBSI.vue";
import BillingVirtualAccountCIMB from "@/components/Billing/BillingVirtualCIMB.vue";
import BillingQR from "@/components/Billing/BillingQR.vue";

export default {
  data() {
    return {
      detailSubscription: {},
    };
  },
  components: {
    ModalHeader,
    BillingVirtualAccountBCA,
    BillingVirtualAccountBRI,
    BillingVirtualAccountBNI,
    BillingVirtualAccountBJB,
    BillingVirtualAccountPERMATA,
    BillingVirtualAccountMandiri,
    BillingVirtualAccountCIMB,
    BillingVirtualAccountBSI,
    BillingQR,
    BModal,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BCard,
    BButton,
    BFormCheckbox,
    BFormRadio,
    BCollapse,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const uuid = this.$route.params.id;
      this.$store
        .dispatch(`subscribe/getDetailSubscription`, {
          uuid: uuid,
        })
        .then((result) => {
          this.detailSubscription = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    prevPage() {
      this.$router.push({ name: "master-billing.index" });
    },
  },
};
</script>
